<template>
  <div style="font-family: Raleway, sans-serif">
    <!-- <TheSidebar/> -->
    <CWrapper>
      <TheHeader />
      <div class="text-left" style="text-align: center; margin-top: 10rem">
        <h4 class="text-center"><b>Dashboard</b></h4>
      </div>

      <div>
        <b-row
          class="pl-4 pl-lg-0 w-100 mt-3 justify-content-center pb-5 gap-5"
        >
          <b-col
            cols="12"
            sm="3"
            class="backblue background w-lg-25 text-center rounded-lg"
          >
            <router-link to="/plans">
              <b-row class="pb-3">
                <b-icon
                  icon="card-list"
                  font-scale="5"
                  class="text-white mt-4 mb-3"
                ></b-icon>
                <p class="mb-1 text-white hover-zoom">My Plans</p>
              </b-row>
            </router-link>
          </b-col>
          <b-col
            cols="12"
            sm="3"
            class="backblue background w-lg-25 text-center rounded-lg"
          >
            <router-link to="/history">
              <b-row class="">
                <b-icon
                  icon="clock-history"
                  font-scale="5"
                  class="text-white mt-4 mb-3"
                ></b-icon>
                <p class="mb-1 text-white">Payments and History</p>
              </b-row>
            </router-link>
          </b-col>

          <b-col
            cols="12"
            sm="3"
            class="backblue background w-lg-25 text-center rounded-lg"
          >
            <router-link to="/profile">
              <b-row class="">
                <b-icon
                  icon="person-check"
                  font-scale="5"
                  class="text-white mt-4 mb-3"
                ></b-icon>
                <p class="mb-1 text-white">My Profile</p>
              </b-row>
            </router-link>
          </b-col>
        </b-row>
      </div>
      <b-modal
        label=""
        size="xl"
        class="modal fade"
        ref="my-modal"
        id="modal-subscribe"
        hide-footer
        hide-header
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div class="modal-header border-0">
          <h4 class="modal-title" id="myModalLabel">
            <b v-if="days_difference <= 7" style="font-weight: 500; color: #e62e53; text-align: center"
              >Your plan to be expired in {{days_difference}} days.</b
            > <b v-if="days_difference > 7" style="font-weight: 500; color: #e62e53; text-align: center"
              >Your Trial plan has been expired .</b
            >
            <!-- <b v-if="days_difference == 7" style="font-weight: 500; color: #e62e53; text-align: center"
              >Your Trial plan expiring today .</b
            > -->
          </h4>
          <h5></h5>
          <button
            type="button"
            style="color: #e62e53"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span @click="closepopup()" aria-hidden="true">&times;</span>
          </button>
        </div>
         <section onmousedown="return false" onselectstart="return false">
      <b-row class="text-center pt-4 w-100">
        <h2 class="pink fw-bolder"><b>Plans</b></h2>
        <hr style="width: 18%; margin-left: 40%; margin-top: 5px" />
      </b-row>
      <b-row class="text-center w-100">
        <b-col cols="12" sm="4"> </b-col>
        <b-col cols="12" sm="4">
          <p style="font-weight: 700">
            Select the Plans which suits you !!
          </p>
        </b-col>
      </b-row>
      <b-card-group deck class="mb-3 px-5"  v-if="userlistdisplay.length>0">
        <b-card
       
          v-for="(list, index) in userlistdisplay.slice(1,-1)"
          :key="index"
          :class="{
            backblue: (index + 1) % 2,
            background_effect: !((index + 1) % 2),
          }"
          cols="12"
          sm="4"
          class="shadow background_effect p-3 mb-5 rounded p-3 text-center"
        >
          <b-row class="" >
            <b-col class="text-center">
              <h2 class="" style="font-weight: 700">{{ list.plan_name }}</h2>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col class="text-left">
              <p>
                <b-row>
                  <b-col sm="8">
                    <b-icon icon="clock" aria-hidden="true"></b-icon>
                    Validity</b-col
                  >
                  <b-col sm="4"
                    ><span
                      >{{ list.plan_period }} {{ list.plan_period_type }}
                    </span>
                  </b-col>
                </b-row>
              </p>
            </b-col>
          </b-row>
          <hr />
          <b-row>
          
            <b-col class="text-left curser-pointer" v-if="list.id == 3">
              <p>
                <b-row id="tooltip-target-1">
                  <b-col sm="8">
                    <b-icon icon="map" aria-hidden="true"></b-icon>
                    <b-icon
                      icon="map"
                      aria-hidden="true"
                      class="tooltip tooltip-scroll"
                    ></b-icon>
                    Countries</b-col
                  >
                  <b-col sm="4"
                    ><span>{{ list.countriescount }} + </span>
                  </b-col>
                  <b-tooltip
                    target="tooltip-target-1"
                    triggers="hover"
                    class="bg-danger"
                    variant="danger"
                  >
                    <div class="w-100 list-style-none wrapper" data-html="true">
                      <div class="tooltip-text">
                        <span class="">
                          <b>Gulf</b> - Uae,Syria,Bahrain,Lebanon
                        </span>
                        <hr />
                        <span>
                          <b>East Africa</b> - <br />
                          KENYA, Uganda, Madagascar, Ethiopia, Djibouti, Malawi,
                          Zambia, Rwanda, Zimbabwe, Mauritius, Botswana,
                          Burundi, Sudan, Seychelles</span>
                        <hr />
                        <br />
                        <span>
                          <b>West Africa</b> -
                          Naminia,Gabon,Nigeria,Cameroon,Benin,Mali,Burkina,Faso,Gambia,Niger </span
                        ><br />
                        <hr />
                        <span>
                          <b>Pacific</b> - Cambodia, Myanmar, Sri Lanka, Brunie,
                          Maldives, Bangladesh </span
                        ><br />
                        <hr />
                        <span>
                          <b>Bulkan & Europe</b> - Bulgaria, Slovenia, Latvia,
                          Montenegro, Austria, Hungary, Cyprus, Lithuania,
                          Belarus, Ukraine </span
                        ><br />
                        <hr />
                        <span>
                          <b>South America</b> - Uruguay, Paraguay, Bolivia,
                          Suriname, Ecuador, Nicaragua
                        </span>
                      </div>
                    </div>
                  </b-tooltip>
                </b-row>
              </p>
            </b-col>
            <b-col class="text-left" v-if="list.id == 4">
              <p>
                <b-row id="tooltip-target-4">
                  <b-col sm="8">
                    <b-icon icon="map" aria-hidden="true"></b-icon>
                    <b-icon 
                      icon="map"
                      aria-hidden="true"
                      class="tooltip tooltip-scroll"
                    ></b-icon>
                    Countries</b-col
                  >
                  <b-col sm="4"
                    ><span>{{ list.countriescount }} + </span>
                  </b-col>
                  <b-tooltip
                    target="tooltip-target-4"
                    triggers="hover"
                    class="bg-danger"
                    variant="danger"
                  >
                    <div class="w-100 list-style-none wrapper" data-html="true">
                      <div class="tooltip-text">
                        <span class="">
                          <b>Silver Plan Countries +</b><br />
                          <hr />
                          <b>Gulf</b> - Saudi Arabia, Yemen, Qatar, Jordan
                        </span>
                        <hr />
                        <span>
                          <b>East Africa</b> - <br />
                          Mozambique, Tanzania</span
                        >
                        <hr />
                        <br />
                        <span>
                          <b>West Africa</b> - Angola, Republic Of Congo,
                          Dr.Congo, Togo, Ghana, Cote D'Ivoire, Mauritania,
                          Guinea, Morocco, Algeria </span
                        ><br />
                        <hr />
                        <span> <b>Pacific</b> - South Korea, Philippines </span
                        ><br />
                        <hr />
                        <span>
                          <b>Bulkan & Europe</b> - Portugal, Greece, Serbia,
                          France, Macedonia, Netherlands, Bosnia
                        </span>
                        <br />
                        <hr />
                        <span>
                          <b>South America</b> - Chile, Brazil, Peru, Costa
                          Rica, Panama
                        </span>
                      </div>
                    </div>
                  </b-tooltip>
                </b-row>
              </p>
            </b-col>
            <b-col class="text-left curser-pointer" v-if="list.id == 5">
              <p>
                <b-row id="tooltip-target-5">
                  <b-col>
                    <b-icon icon="map" aria-hidden="true"></b-icon>
                    <b-icon sm="8"
                      icon="map"
                      aria-hidden="true"
                      class="tooltip tooltip-scroll"
                    ></b-icon>
                    Countries</b-col
                  >
                  <b-col sm="4"
                    ><span>{{ list.countriescount }} + </span>
                  </b-col>
                  <b-tooltip
                    target="tooltip-target-5"
                    triggers="hover"
                    class="bg-danger"
                    variant="danger"
                  >
                    <div class="w-100 list-style-none wrapper" data-html="true">
                      <div class="tooltip-text">
                        <span>
                          <b>Gold Plan Countries </b> + <br />
                          <hr />
                          <b>North America</b> - USA, Canada </span
                        ><br />
                        <hr />
                        <span>
                          <b>Gulf</b> - Kuwait, Iraq, Israel/Palestine, Oman </span
                        ><br />
                        <hr />
                        <span>
                          <b>East Africa</b> - <br />
                          South Africa</span
                        >
                        <hr />
                        <br />
                        <span>
                          <b>West Africa</b> - Tunisia, Libya, Senegal,Liberia,
                          Sierra Leone </span
                        ><br />
                        <hr />
                        <span>
                          <b>Pacific</b> - Thailand, Taiwan, Indonesia, New
                          Zeland, Australia </span
                        ><br />
                        <hr />
                        <span>
                          <b>Bulkan & Europe</b> - Kosovo, UK, Germany, Belgium,
                          Albania, Poland, Romania, Croatia, Italy, Spain,
                          Ireland </span
                        ><br />
                        <hr />
                        <span>
                          <b>South America </b> - Mexico, Colombia, Guatemala,
                          Haiti, Dominican Republic </span
                        ><br />
                      </div>
                    </div>
                  </b-tooltip>
                </b-row>
              </p>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col class="text-left">
              <p>
                <b-row>
                  <b-col sm="8">
                    <b-icon icon="credit-card" aria-hidden="true"></b-icon>
                    Premium Contacts</b-col
                  >
                  <b-col sm="4"
                    ><span>{{ list.prem_contact }} </span>
                  </b-col>
                </b-row>
              </p>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col class="text-left">
              <p>
                <b-row>
                  <b-col sm="8"
                    ><b-icon icon="headset" aria-hidden="true"></b-icon> Support
                  </b-col>
                  <b-col sm="4">24/7 </b-col>
                </b-row>
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-white btn-three34">
              <span v-if="isLoggedIn && list.id != 1" class="text-white" :v-model="cartvalue">
                <a href="javascript:;" @click="goToCart(list.id)"
                  >Buy Now</a></span>
            </b-col>
            <div v-if="list.id == 5" class="" id="Inquiry"></div>
          </b-row>
        </b-card>
      </b-card-group>
    </section>
      </b-modal>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from "../containers/TheSidebar";
import TheHeader from "../containers/TheHeader";
import TheFooter from "../containers/TheFooter";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      popupdisplaynone:null,
      plansdetails:[],
      cartvalue:null,
      popup: null,
      days_difference:null,
      userdetect: {
        user_id12: null,
      },
      userlistdisplay: [],
    };
  },
  name: "Dashboard",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,moment
  },
  computed: {
    ...mapGetters(["user"]),
    isLoggedIn: function () {
      return localStorage.getItem("token");
    },
  },
  mounted() {
    if (this.popup != 1) {
      window.setInterval(() => {
        this.onWindowLoad();
      }, 2000);
    } else {
      this.closepopup();
    }
  },
  watch: {
    popup: function (val) {
      if (val == 1) {
        this.closepopup();
      }
    },
  },
  created() {
    this.fetchplanDynamic();
    this.getUserplan() ;
  },
  methods: {
    ...mapActions(["addToCart12","getUserplanlist"]),
    goToCart(pid) {
      let user = localStorage.getItem("user");
      let token = localStorage.getItem("token");

      this.addToCart12({ id: pid, user_id: user }).then((response) => {
        window.location.href = "/cart";
      });
    },
     fetchplanDynamic() {
      axios.get(process.env.VUE_APP_API_URL + "PlanList").then((resp) => {
        this.userlistdisplay = resp.data.planlist;
      });
    },
    onWindowLoad() {
      if (this.popup != 1 && localStorage.getItem("popup_expirplan") != 1 ) {
        this.$refs["my-modal"].show();
         
    }
        
    },
    getUserplan() {
      var currentDate = new Date();
      this.userdetect.user_id12 = localStorage.getItem("user");
      this.userdetect.token = localStorage.getItem("token");
      
      this.getUserplanlist(this.userdetect).then((res) => {
      this.plansdetails = res.data.data;
      console.log( this.plansdetails)
      if(this.plansdetails.length >1){
       localStorage.setItem("popup_expirplan", 1);
      }
      if(this.plansdetails[0]){      
        var currentDateWithFormat = new Date();
        var myPastDate=new Date(this.plansdetails[0].created_at); 
        
        var time_difference = currentDateWithFormat.getTime() - myPastDate.getTime();
        if(time_difference / (1000 * 60 * 60 * 24) < 7 ){
          this.days_difference = (7 - (time_difference / (1000 * 60 * 60 * 24))).toFixed(0);  
        }else if(time_difference / (1000 * 60 * 60 * 24) == 7 ){
          this.days_difference = (7 - (time_difference / (1000 * 60 * 60 * 24))).toFixed(0);  
        }else{
          this.days_difference = 8;
        }
       this.onWindowLoad() ;
      }

      });
    },
    closepopup() {
      this.$refs["my-modal"].hide();
      this.popup = 1;
      localStorage.setItem("popup_expirplan", 1);
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
  },
};
</script>
<style scoped>
.about {
  transition: box-shadow 0.3s;
}
.about:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
.backblue {
  background: #1e38a7;

  padding: 25px;
  /* border-radius: 25px !important; */
  transition: box-shadow 0.3s;
}
.raise:hover,
.raise:focus {
  box-shadow: 1px 1px 3px 3px rgb(235, 230, 230);
  transform: translateY(-0.25em);
}
.batn {
  background: #1e38a7;
  color: white;
  border: none;
  border-radius: 28px;
  padding-left: 1rem;
  padding-right: 1rem;
}
.batn:hover {
  background: #002ef8;
  color: white;
  border: none;
  border-radius: 28px;
  padding-left: 1rem;
  padding-right: 1rem;
}
.background_effect:nth-child(odd) {
  background: #1e38a7 !important;
  color: #f8fbfd;
}
.background_effect:nth-child(odd) span:hover a {
  color: white;
}
.background_effect:nth-child(even) span:hover a {
  color: #fa2b56;
}
.bg-pink {
  background-color: #fa2b56;
}
.pink {
  color: #fa2b56;
}
.btn-three34 span a {
  text-decoration: none;
}
.btn-three34 span {
  color: #fff;
  font-weight: 700;
  transition: all 0.5s;
  position: relative;
  padding: 0.5rem 1rem;
  background-color: #fa2b56;
  border-radius: 25px;
}
.btn-three34:hover span {
  background: none;
  border: 1px solid #fa2b56;
  color: #fa2851 !important;
}
</style>
